/*
Minimum API Version Requirements by Date
v2.8 - 5 October 2018
v2.9 - 18 April 2019
v2.10 - 22 July 2019
v2.11 - 5 November 2019
v2.12 - 28 January 2020
v3.0 - 5 May 2020
v3.1 - 28 July 2020
* v3.2 - 27 October 2020 
* v7.0 * // Current Version Implemented
*/

(function () {
    /** facebook service wrapper - this service requires the facebook sdk first **/
    SUGApp.service('facebookService', ['$q','SUGUtilities','$window', function($q,util,w){

        var service = {
            fbConnected: false,
            fbLoading: false
        };
        var setFbConnected = function (connected) {
            service.fbConnected = connected ? true : false;
        };
        var setFbLoading = function (processing) {
            service.fbLoading = processing ? true : false;
        };
        setFbConnected(false);
        setFbLoading(true);

        // method initialize facebook
        service.initFB = function(fbAppId){
            FB.init({
                appId: fbAppId,
                status: true,
                cookie: true,
                xfbml: true,
                version: 'v7.0'
            });
        };

        // this needs to run as soon as we know we have the Facebook SDK available
        var setupFB = function () {
            setFbLoading(false);

            // listens for the init status update from the FB SDK
            FB.Event.subscribe('auth.statusChange', function (response) {
                if (response.status === 'connected') {
                    // already logged into the app, just wait for that button clicky goodness
                    setFbConnected(true);
                } else {
                    setFbConnected(false);
                }
            });

            // get the fb application id from the application config and init
            util.APISyncCall("post", "", '', "c.getsmappid", 15000).then(function (response) {
                var r = util.lowerCaseKeys(response);
                if (angular.isDefined(r.data) && angular.isDefined(r.data.data) && r.data.data !== '') {
                    // manually initialize facebook after the service is loaded, listener is running, and facebook appId is present
                    service.initFB(r.data.data);
                }
            });
        };
        
        // this is where the fun begins
        if (!window.FB) {
            // do not implement this in other places, we are limited to this one window scoped variable and need to protect it
            window.fbAsyncInit = function () {
                setupFB();
            };
        
            // Load the Facebook SDK asynchronously since it hasn't already been loaded
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        
        } else {
            // the Facebook SDK has already been loaded, so proceed
            setupFB();
        }

        // get user information
        service.getFBUserInfo = function( authResponse ){
            var response = util.lowerCaseKeys( authResponse );
            var data = {
                atId : response.accesstoken,
                uidId : response.userid,
                sigId : response.signedrequest
            };
            return util.APISyncCall( "post", "", data, "c.getSocialMediaUser", 15000 );
        };

        var fbResultHandler = function (result,deferred) {
            var r = util.lowerCaseKeys(result);
            if (r.status && r.status == 'connected' && r.authresponse && r.authresponse.accesstoken && r.authresponse.accesstoken.length) {
                setFbConnected(true);
                deferred.resolve(r);
            } else {
                setFbLoading(false);
                setFbConnected(false);
                deferred.reject('Error occured');
            }
            return deferred;
        };

        // facebook login
        service.fbLogin = function(){
            var deferred = $q.defer();
            
            if (!window.FB) {
                // still loading, can't do anything :/
                deferred.reject('Facebook SDK is still loading');

            } else {
                // Facebook SDK present, good to go
                setFbLoading(true);
            
                if (service.fbConnected) {
                    // should still be 'connected' if this runs, but check just in case
                    FB.getLoginStatus(function (result) {
                        deferred = fbResultHandler(result,deferred);
                    }, true);
            
                } else {
                    // not connected yet, do a fresh login
                    FB.login(function (result) {
                        deferred = fbResultHandler(result,deferred);
                    }, {
                        scope: 'public_profile,email'
                    });
                }
            }

            return deferred.promise;
        };

        // return service object
        return service;

    }]);

})();
